import { render, staticRenderFns } from "./AddStops.vue?vue&type=template&id=bf441f62&scoped=true"
import script from "./AddStops.vue?vue&type=script&lang=js"
export * from "./AddStops.vue?vue&type=script&lang=js"
import style0 from "./AddStops.vue?vue&type=style&index=0&id=bf441f62&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf441f62",
  null
  
)

export default component.exports