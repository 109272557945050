<template>
  <div class="my-3">
    <div class="text-h4 mb-2 d-flex justify-space-between align-center">
      <span>
        {{ $t("add_drop_off") }}
      </span>
      <span>
        <add-location
          :disabled="!items.tenant_id"
          :tenant-id="items.tenant_id"
        />
      </span>
    </div>
    <v-row
      v-for="(r, i) in value"
      :key="i"
    >
      <v-col cols="8">
        <v-select
          v-model="value[i]"
          dense
          :loading="isLocLoading"
          :items="locOptions"
          item-text="location"
          item-value="id"
          :label="$t('drop_off') + ` ${i + 1} *`"
          :rules="[rules.required]"
          @change="changeStop"
        />
        <!-- :item-disabled="checkPrevious" -->
        <!-- return-object -->
      </v-col>
      <!-- v-if="!value[i + 1]" -->
      <v-col cols="3">
        <div class="actionBtn">
          <!-- v-if="!value[i + 1]" -->
          <v-btn
            v-if="i == value.length - 1"
            color="primary"
            dark
            x-small
            @click="() => value.push(0)"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <!-- v-if="i >= 1" -->
          <v-btn
            v-if="value.length !== 1"
            color="danger"
            dark
            x-small
            @click="deleteStop(i)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";

export default {
  components: {
    addLocation: () => import("./AddLocation.vue"),
  },
  mixins: [validationRules],

  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default() {
        return "";
      },
    },
    items: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      isLocLoading: "truckistanRoute/getLocationsLoading",
      locOptions: "truckistanRoute/getLocationOptions",
    }),
  },
  mounted() {
    if (this.type === "create") {
      this.value.push(0);
    }
  },
  methods: {
    changeStop() {
      this.$emit("input", this.value);
    },
    checkPrevious(v) {
      const ids = [...this.value];
      if (this.items?.start_location_id) {
        ids.push(this.items?.start_location_id);
      }

      return ids.includes(v.id);
    },
    deleteStop(v) {
      const val = this.value[v];
      const arr = this.value.filter((r) => r !== val);
      this.$emit("input", arr);
    },
  },
};
</script>
<style lang="sass" scoped>
.actionBtn
  display: flex
  gag:0.5rem !important
  .danger
    background: red
</style>
